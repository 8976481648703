import {z} from 'zod';

import {AvailableDiscountSchema, DiscountSourceTypeSchema} from '@dms/api';

import {zodDate, zodNumber, zodString} from 'shared';

export const EditMaterialItemFormSchema = z.object({
  neededAt: zodDate,
  quantity: zodNumber.required(),
  sellingPricePerUnit: zodNumber.required(),
  vatType: zodString.required(),
  vatFreeCode: zodString,
  mechanic: zodString,
  isUnitPriceWithVat: z.boolean().default(false),
  isCustomPrice: z.boolean().default(false),
  discount: z.object({
    isDoNotApplyDiscount: z.boolean().default(false),
    isDiscountEnable: z.boolean().default(false),
    discountSourceType: DiscountSourceTypeSchema.nullish(),
    discountRate: zodNumber,
    availableDiscounts: z.array(
      AvailableDiscountSchema.extend({discountRate: zodNumber.minMax(0, 100)})
    ),
  }),
  // Used only locally to be able to control the getCurrentPrice action recalculations
  shouldGetCurrentPrice: z.boolean().default(false),
});

export type EditMaterialItemForm = z.infer<typeof EditMaterialItemFormSchema>;
