import {startOfToday} from 'date-fns';
import {z} from 'zod';

import {defaultTo} from 'ramda';

import i18n from '@dms/i18n';

import {precisionCalculation, zodDate, zodNumber, zodString} from 'shared';

export const reservationFormSchema = (isNoteRequired: boolean, dispensingUnit: number) =>
  z.object({
    quantity: zodNumber
      .min(0.0001)
      .required()
      .refine((value) => precisionCalculation.modulo(defaultTo(0, value), dispensingUnit) === 0, {
        message: `${i18n.t(
          'entity.warehouse.labels.quantityMustBeMultipleOfDispensingUnit'
        )} (${dispensingUnit})`,
      }),
    requestExpiresAtDate: zodDate.min(
      startOfToday(),
      i18n.t('entity.warehouse.notifications.dateCantBeSetToPast')
    ),
    requestExpiresAtTime: zodString,
    note: isNoteRequired ? zodString.required() : zodString,
  });
