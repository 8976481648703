import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  AccountingDocumentRecalculateApiArg,
  AccountingDocumentRecalculateApiRes,
  CancelInvoiceApiArg,
  CancelInvoiceApiResponse,
  CancelInvoiceProformaApiArg,
  CancelInvoiceProformaApiResponse,
  ChangeInvoiceCustomerApiArg,
  CreateCashReceiptV4ApiArg,
  CreateCashReceiptV4ApiResponse,
  CreateCheckoutCorrectiveTaxDocumentApiArg,
  CreateCheckoutCorrectiveTaxDocumentApiRes,
  CreateCorrectiveTaxDocumentV4ApiArg,
  CreateCorrectiveTaxDocumentV4ApiResponse,
  CreateDiscountItemApiArg,
  CreateDiscountItemApiResponse,
  CreateDocumentSeriesDefinitionApiArg,
  CreateDocumentSeriesDefinitionApiResponse,
  CreateInvoiceDraftApiArg,
  CreateInvoiceDraftApiResponse,
  CreateInvoiceProformaV4ApiArg,
  CreateInvoiceProformaV4ApiResponse,
  CreateInvoiceV4ApiArg,
  CreateInvoiceV4ApiResponse,
  CreateManuallyTaxDocumentForPaymentApiArg,
  CreateManuallyTaxDocumentForPaymentApiResponse,
  CreateMarginItemApiArg,
  CreateMarginItemApiResponse,
  CreatePreAccountingProcessingSettingsApiArg,
  CreatePreAccountingProcessingSettingsApiResponse,
  CreateStandardItemApiArg,
  CreateStandardItemApiResponse,
  CreateStandardMultipleItemsApiArg,
  CreateStandardMultipleItemsApiResponse,
  CreateTaxDocumentSettingApiArg,
  CreateTaxDocumentSettingApiResponse,
  CreateVatTotalAndCalculationSettingApiArg,
  CreateVatTotalAndCalculationSettingApiResponse,
  DeleteDocumentSeriesDefinitionApiArg,
  DeleteDocumentSeriesDefinitionApiResponse,
  DeleteInvoiceDraftApiArg,
  DeleteInvoiceDraftApiResponse,
  DeletePreAccountingProcessingSettingsApiArg,
  DeletePreAccountingProcessingSettingsApiResponse,
  DeleteTaxDocumentSettingApiArg,
  DeleteTaxDocumentSettingApiResponse,
  DeleteVatTotalAndCalculationSettingApiArg,
  DeleteVatTotalAndCalculationSettingApiResponse,
  GetBalanceInvoiceApiArg,
  GetBalanceInvoiceApiResponse,
  GetBalanceInvoiceByInvoiceProformaIdApiArg,
  GetBalanceInvoiceByInvoiceProformaIdApiResponse,
  GetBalanceInvoiceByInvoiceProformaIdV4ApiArg,
  GetBalanceInvoiceByInvoiceProformaIdV4ApiResponse,
  GetBalanceInvoiceV4ApiArg,
  GetBalanceInvoiceV4ApiResponse,
  GetCalculateByPercentageApiArg,
  GetCalculateByPercentageApiRes,
  GetCalculatePercentageApiArg,
  GetCalculatePercentageApiRes,
  GetCashReceiptV4ApiArg,
  GetCashReceiptV4ApiResponse,
  GetCorrectiveTaxDocumentPaymentListApiArg,
  GetCorrectiveTaxDocumentPaymentListApiResponse,
  GetCorrectiveTaxDocumentV4ApiArg,
  GetCorrectiveTaxDocumentV4ApiResponse,
  GetDocumentSeriesDefinitionListApiResponse,
  GetInvoiceDraftApiArg,
  GetInvoiceDraftApiResponse,
  GetInvoicePaymentListApiArg,
  GetInvoicePaymentListApiResponse,
  GetInvoiceProformaPaymentListApiArg,
  GetInvoiceProformaPaymentListApiResponse,
  GetInvoiceProformaV4ApiArg,
  GetInvoiceProformaV4ApiResponse,
  GetInvoiceV4ApiArg,
  GetInvoiceV4ApiResponse,
  GetPaymentTypeEnumApiResponse,
  GetPaymentTypesForInvoicePaymentApiArg,
  GetPaymentTypesForInvoicePaymentApiResponse,
  GetPreAccountingProcessingSettingsApiArg,
  GetPreAccountingProcessingSettingsApiResponse,
  GetTaxDocumentSettingApiArg,
  GetTaxDocumentSettingApiResponse,
  GetTaxDocumentSettingListApiArg,
  GetTaxDocumentSettingListApiResponse,
  GetVatTotalAndCalculationSettingApiArg,
  GetVatTotalAndCalculationSettingApiResponse,
  PayWithBankTransferApiArg,
  PayWithBankTransferApiResponse,
  PayWithBankTransferArg,
  PayWithBankTransferRes,
  PayWithCardAndCreateReceiptApiArg,
  PayWithCardAndCreateReceiptApiResponse,
  PayWithCardApiArg,
  PayWithCardApiResponse,
  PayWithCardArg,
  PayWithCardReceiptArg,
  PayWithCardReceiptRes,
  PayWithCardRes,
  PayWithCashAndCreateReceiptApiArg,
  PayWithCashAndCreateReceiptApiResponse,
  PayWithCashApiArg,
  PayWithCashApiResponse,
  PayWithCashArg,
  PayWithCashReceiptArg,
  PayWithCashReceiptRes,
  PayWithCashRes,
  RenderBalanceInvoiceDocumentApiArg,
  RenderBalanceInvoiceDocumentApiResponse,
  RenderCorrectiveTaxDocumentApiArg,
  RenderCorrectiveTaxDocumentApiResponse,
  RenderExpenseCashReceiptDocumentApiArg,
  RenderExpenseCashReceiptDocumentApiResponse,
  RenderIncomeCashReceiptDocumentApiArg,
  RenderIncomeCashReceiptDocumentApiResponse,
  RenderInvoiceDocumentApiArg,
  RenderInvoiceDocumentApiResponse,
  RenderInvoiceProformaDocumentApiArg,
  RenderInvoiceProformaDocumentApiResponse,
  RenderTaxDocumentForPaymentDocumentApiArg,
  RenderTaxDocumentForPaymentDocumentApiResponse,
  SendCorrectiveTaxDocumentApiArg,
  SendCorrectiveTaxDocumentApiResponse,
  SendInvoiceApiArg,
  SendInvoiceApiResponse,
  TaxDocumentCalculationApiArg,
  UpdateDocumentSeriesDefinitionApiArg,
  UpdateDocumentSeriesDefinitionApiResponse,
  UpdateInvoiceDraftApiArg,
  UpdateInvoiceDraftApiResponse,
  UpdatePreAccountingProcessingSettingsApiArg,
  UpdatePreAccountingProcessingSettingsApiResponse,
  UpdateTaxDocumentSettingApiArg,
  UpdateTaxDocumentSettingApiResponse,
  UpdateVatTotalAndCalculationSettingApiArg,
  UpdateVatTotalAndCalculationSettingApiResponse,
  CreateCorrectiveTaxDocumentSettingApiArg,
  CreateCorrectiveTaxDocumentSettingApiResponse,
  DeleteCorrectiveTaxDocumentSettingApiArg,
  DeleteCorrectiveTaxDocumentSettingApiResponse,
  GetCorrectiveTaxDocumentSettingApiArg,
  GetCorrectiveTaxDocumentSettingApiResponse,
  UpdateCorrectiveTaxDocumentSettingApiArg,
  UpdateCorrectiveTaxDocumentSettingApiResponse,
  GetTaxDocumentForPaymentV4ApiResponse,
  GetTaxDocumentForPaymentV4ApiArg,
} from '../types/accounting';
import {
  CancelCashRegisterDocumentApiArg,
  CancelCorrectiveTaxDocumentApiArg,
  CancelCorrectiveTaxDocumentApiResponse,
  CreateCashRegisterApiArg,
  CreateCashRegisterApiResponse,
  CreateDocumentLimitDefinitionApiArg,
  CreateDocumentLimitDefinitionApiResponse,
  CreateInvoiceMarginApiArg,
  CreateInvoiceMarginApiRes,
  CreateRoundingDefinitionApiArg,
  CreateRoundingDefinitionApiResponse,
  CreateVatCheckTotalsDefinitionApiArg,
  CreateVatCheckTotalsDefinitionApiResponse,
  DeleteInvoiceMarginApiArg,
  DeleteInvoiceMarginApiRes,
  DeleteNegativeDocumentCancellationApiArg,
  DeleteNegativeDocumentCancellationApiRes,
  DeleteProformaGenerationApiArg,
  DeleteProformaGenerationApiRes,
  DeleteRoundingDefinitionApiArg,
  DeleteRoundingDefinitionApiResponse,
  EditInvoiceMarginApiArg,
  EditInvoiceMarginApiRes,
  GetCashRegisterApiArg,
  GetCashRegisterApiResponse,
  GetCashRegisterDocumentKindOfPaymentListApiArg,
  GetCashRegisterDocumentKindOfPaymentListApiResponse,
  GetCashRegisterListApiArg,
  GetCashRegisterListApiResponse,
  GetDocumentLimitDefinitionApiArg,
  GetDocumentLimitDefinitionApiResponse,
  GetDocumentLimitDefinitionListApiArg,
  GetDocumentLimitDefinitionListApiResponse,
  GetDocumentRestrictionApiArg,
  GetDocumentRestrictionApiResponse,
  GetInvoiceMarginApiArg,
  GetInvoiceMarginApiRes,
  GetInvoiceMarginsApiRes,
  GetNegativeDocumentCancellationApiArg,
  GetNegativeDocumentCancellationApiRes,
  GetProformaGenerationApiArg,
  GetProformaGenerationApiRes,
  GetRoundingDefinitionApiArg,
  GetRoundingDefinitionApiResponse,
  GetRoundingDefinitionListApiArg,
  GetRoundingDefinitionListApiResponse,
  GetRoundStepApiResponse,
  GetVatCalculationDefinitionApiArg,
  GetVatCalculationDefinitionApiResponse,
  InactivateCashRegisterApiArg,
  InactivateCashRegisterApiResponse,
  PatchCashRegisterApiArg,
  PatchCashRegisterApiResponse,
  PostNegativeDocumentCancellationApiArg,
  PostNegativeDocumentCancellationApiRes,
  PostProformaGenerationApiArg,
  PostProformaGenerationApiRes,
  PutProformaGenerationApiArg,
  PutProformaGenerationApiRes,
  RemoveDocumentLimitDefinitionApiArg,
  RemoveDocumentLimitDefinitionApiResponse,
  TaxDocumentCalculationV2ApiResponse,
  UpdateDocumentLimitDefinitionApiArg,
  UpdateDocumentLimitDefinitionApiResponse,
  UpdateNegativeDocumentCancellationApiArg,
  UpdateNegativeDocumentCancellationApiRes,
  UpdateRoundingDefinitionApiArg,
  UpdateRoundingDefinitionApiResponse,
} from '../types/api';
import {omneticApi} from './baseApi/omneticApi';

export const accountingApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getCashRegisterDocumentKindOfPaymentList: build.query<
      GetCashRegisterDocumentKindOfPaymentListApiResponse,
      GetCashRegisterDocumentKindOfPaymentListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register-document/kind-of-payment`,
      }),
    }),
    createCashRegister: build.mutation<CreateCashRegisterApiResponse, CreateCashRegisterApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register`,
        method: 'POST',
        body: queryArg.createCashRegisterRequestBody,
      }),
      invalidatesTags: ['cashRegisters'],
    }),
    getCashRegisterList: build.query<GetCashRegisterListApiResponse, GetCashRegisterListApiArg>({
      query: (params) => ({
        url: `/dms/v1/cash-register`,
        params,
      }),
      providesTags: ['CurrentUser'],
    }),
    getTenantRegisterList: build.query<GetCashRegisterListApiResponse, GetCashRegisterListApiArg>({
      query: (params) => ({
        url: `/dms/v1/cash-register`,
        omitHeaders: ['X-Branch'],
        params,
      }),
      providesTags: ['CurrentUser', 'cashRegisters'],
    }),
    getCashRegister: build.query<GetCashRegisterApiResponse, GetCashRegisterApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register/${queryArg.cashRegisterId}`,
        headers: {'X-Branch': queryArg['X-Branch']},
      }),
      keepUnusedDataFor: 0,
    }),
    patchCashRegister: build.mutation<PatchCashRegisterApiResponse, PatchCashRegisterApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register/${queryArg.cashRegisterId}`,
        method: 'PATCH',
        body: queryArg.patchCashRegisterRequestBody,
      }),
      invalidatesTags: ['cashRegisters'],
    }),
    inactivateCashRegister: build.mutation<
      InactivateCashRegisterApiResponse,
      InactivateCashRegisterApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register/${queryArg.cashRegisterId}/inactivate`,
        method: 'POST',
      }),
      invalidatesTags: ['cashRegisters'],
    }),
    cancelCashRegisterDocument: build.mutation<null, CancelCashRegisterDocumentApiArg>({
      invalidatesTags: (result, error, queryArg) => [
        {type: 'CashRegisterDocument', id: queryArg.cashRegisterDocumentId},
        'cashRegisters',
        'correctiveTaxDocument',
      ],
      queryFn: async (queryArg, queryApi, options, fetch) => {
        const successfulResponse = {data: null};

        const canceledDocument = await fetch({
          url: `/dms/v2/cash-receipt/${queryArg.cashRegisterDocumentId}/cancel`,
          method: 'POST',
          body: queryArg.cancelCashRegisterDocumentRequestBody,
        });

        // if query is successful, return data
        if (isNil(canceledDocument.error)) {
          return successfulResponse;
        }

        // is true when there is BC payment linked to canceled register document
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const isBusinessCaseIdError = (canceledDocument?.error as any)?.data?.errors?.some(
          (item: {data?: {businessCaseId?: string}}) => isNotNil(item?.data?.businessCaseId)
        );

        if (isBusinessCaseIdError) {
          // withdraw linked payment
          const withdrawalPayment = await fetch({
            url: `/dms/v1/checkout/cash-register-document/${queryArg.cashRegisterDocumentId}/withdraw-payment`,
            method: 'PUT',
          });

          // check for errors
          if (withdrawalPayment.error) {
            throw withdrawalPayment.error;
          }

          return successfulResponse;
        } else {
          throw canceledDocument.error;
        }
      },
    }),
    createCashReceiptV4: build.mutation<CreateCashReceiptV4ApiResponse, CreateCashReceiptV4ApiArg>({
      query: (queryArg) => ({
        url: `/dms/v4/cash-receipt`,
        method: 'POST',
        body: queryArg.createCashRegisterDocumentRequestBody,
      }),
    }),
    getCashReceiptV4: build.query<GetCashReceiptV4ApiResponse, GetCashReceiptV4ApiArg>({
      query: (queryArg) => ({
        url: `/dms/v4/cash-receipt/${queryArg.cashRegisterDocumentId}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: (result, error, queryArg) => [
        {type: 'CashRegisterDocument', id: queryArg.cashRegisterDocumentId},
      ],
    }),
    createDocumentLimitDefinition: build.mutation<
      CreateDocumentLimitDefinitionApiResponse,
      CreateDocumentLimitDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register-document-limit-definition`,
        method: 'POST',
        body: queryArg.createDocumentLimitDefinitionRequestBody,
      }),
      invalidatesTags: ['cashRegisterLimitDefinition'],
    }),
    getDocumentLimitDefinitionList: build.query<
      GetDocumentLimitDefinitionListApiResponse,
      GetDocumentLimitDefinitionListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register-document-limit-definition`,
      }),
      providesTags: ['cashRegisterLimitDefinition'],
    }),
    getDocumentLimitDefinition: build.query<
      GetDocumentLimitDefinitionApiResponse,
      GetDocumentLimitDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register-document-limit-definition/${queryArg.id}`,
      }),
      providesTags: ['cashRegisterLimitDefinition'],
    }),
    removeDocumentLimitDefinition: build.mutation<
      RemoveDocumentLimitDefinitionApiResponse,
      RemoveDocumentLimitDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register-document-limit-definition/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['cashRegisterLimitDefinition'],
    }),
    updateDocumentLimitDefinition: build.mutation<
      UpdateDocumentLimitDefinitionApiResponse,
      UpdateDocumentLimitDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/cash-register-document-limit-definition/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateDocumentLimitDefinitionRequestBody,
      }),
      invalidatesTags: ['cashRegisterLimitDefinition'],
    }),
    getDocumentRestriction: build.query<
      GetDocumentRestrictionApiResponse,
      GetDocumentRestrictionApiArg
    >({
      query: (params) => ({
        url: `/dms/v1/document-restriction`,
        params,
      }),
    }),
    cancelCorrectiveTaxDocument: build.mutation<
      CancelCorrectiveTaxDocumentApiResponse,
      CancelCorrectiveTaxDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/corrective-tax-document/cancel/${queryArg.correctiveTaxDocumentId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'correctiveTaxDocument', correctiveTaxDocumentId: queryArg.correctiveTaxDocumentId},
      ],
    }),
    createCheckoutCorrectiveTaxDocument: build.mutation<
      CreateCheckoutCorrectiveTaxDocumentApiRes,
      CreateCheckoutCorrectiveTaxDocumentApiArg
    >({
      query: ({checkoutId, orderId, paymentId, type, requestBody}) => ({
        url: `/dms/v2/checkout/${checkoutId}/order/${orderId}/payment/${paymentId}/${type}-corrective-tax-document`,
        method: 'POST',
        body: requestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'offer', id: queryArg.orderId},
        'invoicePayment',
        'Checkout',
        'documentsCount',
        'invoiceProformaPaymentList',
        'paymentPrescription',
        'SaleVehicle',
        'BusinessCaseDetail',
      ],
    }),
    sendCorrectiveTaxDocument: build.mutation<
      SendCorrectiveTaxDocumentApiResponse,
      SendCorrectiveTaxDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/corrective-tax-document/send/${queryArg.correctiveTaxDocumentId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'correctiveTaxDocument', correctiveTaxDocumentId: queryArg.correctiveTaxDocumentId},
      ],
    }),
    renderBalanceInvoiceDocument: build.mutation<
      RenderBalanceInvoiceDocumentApiResponse,
      RenderBalanceInvoiceDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/accounting/document/balance-invoice/render`,
        method: 'PUT',
        body: queryArg.renderBalanceInvoiceDocumentRequestBody,
      }),
    }),
    renderCorrectiveTaxDocument: build.mutation<
      RenderCorrectiveTaxDocumentApiResponse,
      RenderCorrectiveTaxDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/accounting/document/corrective-tax-document/render`,
        method: 'PUT',
        body: queryArg.renderCorrectiveTaxDocumentRequestBody,
      }),
    }),
    renderExpenseCashReceiptDocument: build.mutation<
      RenderExpenseCashReceiptDocumentApiResponse,
      RenderExpenseCashReceiptDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/document/expense-cash-receipt/render`,
        method: 'PUT',
        body: queryArg.renderExpenseCashReceiptDocumentRequestBody,
      }),
    }),
    renderIncomeCashReceiptDocument: build.mutation<
      RenderIncomeCashReceiptDocumentApiResponse,
      RenderIncomeCashReceiptDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/document/income-cash-receipt/render`,
        method: 'PUT',
        body: queryArg.renderIncomeCashReceiptDocumentRequestBody,
      }),
    }),
    renderInvoiceDocument: build.mutation<
      RenderInvoiceDocumentApiResponse,
      RenderInvoiceDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/accounting/document/invoice/render`,
        method: 'PUT',
        body: queryArg.renderInvoiceDocumentRequestBody,
      }),
    }),
    renderInvoiceProformaDocument: build.mutation<
      RenderInvoiceProformaDocumentApiResponse,
      RenderInvoiceProformaDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/accounting/document/invoice-proforma/render`,
        method: 'PUT',
        body: queryArg.renderInvoiceProformaDocumentRequestBody,
      }),
    }),
    renderTaxDocumentForPaymentDocument: build.mutation<
      RenderTaxDocumentForPaymentDocumentApiResponse,
      RenderTaxDocumentForPaymentDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/accounting/document/tax-document-for-payment/render`,
        method: 'PUT',
        body: queryArg.renderTaxDocumentForPaymentDocumentRequestBody,
      }),
    }),
    createManuallyTaxDocumentForPayment: build.mutation<
      CreateManuallyTaxDocumentForPaymentApiResponse,
      CreateManuallyTaxDocumentForPaymentApiArg
    >({
      query: (body) => ({
        url: `/dms/v1/tax-document-for-payment`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['invoiceProformaPaymentList'],
    }),
    cancelInvoice: build.mutation<CancelInvoiceApiResponse, CancelInvoiceApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/invoice/cancel/${queryArg.invoiceId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoice', invoiceId: queryArg.invoiceId},
      ],
    }),
    createDocumentSeriesDefinition: build.mutation<
      CreateDocumentSeriesDefinitionApiResponse,
      CreateDocumentSeriesDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/invoice/document-series-definition`,
        method: 'POST',
        body: queryArg.documentSeriesDefinitionRequestBody,
      }),
      invalidatesTags: ['DocumentSeriesDefinition'],
    }),
    getDocumentSeriesDefinitionList: build.query<GetDocumentSeriesDefinitionListApiResponse, void>({
      query: () => ({
        url: `/dms/v1/invoice/document-series-definition`,
      }),
      providesTags: ['DocumentSeriesDefinition'],
    }),
    deleteDocumentSeriesDefinition: build.mutation<
      DeleteDocumentSeriesDefinitionApiResponse,
      DeleteDocumentSeriesDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/invoice/document-series-definition/${queryArg.documentSeriesDefinitionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['DocumentSeriesDefinition'],
    }),
    updateDocumentSeriesDefinition: build.mutation<
      UpdateDocumentSeriesDefinitionApiResponse,
      UpdateDocumentSeriesDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/invoice/document-series-definition/${queryArg.documentSeriesDefinitionId}`,
        method: 'PUT',
        body: queryArg.documentSeriesDefinitionRequestBody,
      }),
      invalidatesTags: ['DocumentSeriesDefinition'],
    }),
    replaceInvoiceCustomer: build.mutation<void, ChangeInvoiceCustomerApiArg>({
      query: ({invoiceId, ...body}) => ({
        url: `/dms/v1/invoice/${invoiceId}/customer`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoice', invoiceId: queryArg.invoiceId},
      ],
    }),
    getInvoicePaymentList: build.query<
      GetInvoicePaymentListApiResponse,
      GetInvoicePaymentListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/invoice/payment/payment/${queryArg.invoiceId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'invoicePayment', invoiceId: queryArg.invoiceId},
      ],
    }),
    getCorrectiveTaxDocumentPaymentList: build.query<
      GetCorrectiveTaxDocumentPaymentListApiResponse,
      GetCorrectiveTaxDocumentPaymentListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/corrective-tax-document/payment/payment/${queryArg.correctiveTaxDocumentId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'invoicePayment', invoiceId: queryArg.correctiveTaxDocumentId},
        'paymentPrescription',
      ],
    }),
    sendInvoice: build.mutation<SendInvoiceApiResponse, SendInvoiceApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/invoice/send/${queryArg.invoiceId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoice', invoiceId: queryArg.invoiceId},
      ],
    }),
    deleteInvoiceDraft: build.mutation<DeleteInvoiceDraftApiResponse, DeleteInvoiceDraftApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/invoice-draft/${queryArg.invoiceDraftId}`,
        method: 'DELETE',
      }),
    }),
    cancelInvoiceProforma: build.mutation<
      CancelInvoiceProformaApiResponse,
      CancelInvoiceProformaApiArg
    >({
      query: ({invoiceProformaId}) => ({
        url: `/dms/v1/invoice-proforma/${invoiceProformaId}/cancel`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoiceProforma', invoiceId: queryArg.invoiceProformaId},
      ],
    }),
    getInvoiceProformaPaymentList: build.query<
      GetInvoiceProformaPaymentListApiResponse,
      GetInvoiceProformaPaymentListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/invoice-proforma/payment/payment/${queryArg.invoiceId}`,
      }),
      providesTags: ['invoiceProformaPaymentList'],
    }),
    getPaymentTypesForInvoicePayment: build.query<
      GetPaymentTypesForInvoicePaymentApiResponse,
      GetPaymentTypesForInvoicePaymentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/payment-type/invoice`,
      }),
    }),
    createRoundingDefinition: build.mutation<
      CreateRoundingDefinitionApiResponse,
      CreateRoundingDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rounding-definition`,
        method: 'POST',
        body: queryArg.createRoundingDefinitionRequestBody,
      }),
      invalidatesTags: ['roundingDefinitions'],
    }),
    getRoundingDefinitionList: build.query<
      GetRoundingDefinitionListApiResponse,
      GetRoundingDefinitionListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rounding-definition`,
      }),
      providesTags: ['roundingDefinitions'],
    }),
    deleteRoundingDefinition: build.mutation<
      DeleteRoundingDefinitionApiResponse,
      DeleteRoundingDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rounding-definition/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['roundingDefinitions'],
    }),
    getRoundingDefinition: build.query<
      GetRoundingDefinitionApiResponse,
      GetRoundingDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rounding-definition/${queryArg.id}`,
      }),
      providesTags: ['roundingDefinitions'],
    }),
    updateRoundingDefinition: build.mutation<
      UpdateRoundingDefinitionApiResponse,
      UpdateRoundingDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rounding-definition/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateRoundingDefinitionRequestBody,
      }),
      invalidatesTags: ['roundingDefinitions'],
    }),
    payInvoiceWithBankTransfer: build.mutation<PayWithBankTransferRes, PayWithBankTransferArg>({
      query: ({invoiceId, ...body}) => ({
        url: `/dms/v1/invoice/payment/pay-bank-transfer/${invoiceId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoicePayment', invoiceId: queryArg.invoiceId},
        {type: 'invoice', invoiceId: queryArg.invoiceId},
      ],
    }),
    payInvoiceWithCard: build.mutation<PayWithCardRes, PayWithCardArg>({
      query: ({invoiceId, ...body}) => ({
        url: `/dms/v1/invoice/payment/pay-card/${invoiceId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoicePayment', invoiceId: queryArg.invoiceId},
        {type: 'invoice', invoiceId: queryArg.invoiceId},
      ],
    }),
    payInvoiceWithCardReceipt: build.mutation<PayWithCardReceiptRes, PayWithCardReceiptArg>({
      query: ({invoiceId, ...body}) => ({
        url: `/dms/v1/invoice/payment/pay-card-receipt/${invoiceId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoicePayment', invoiceId: queryArg.invoiceId},
        {type: 'invoice', invoiceId: queryArg.invoiceId},
      ],
    }),
    payInvoiceWithCash: build.mutation<PayWithCashRes, PayWithCashArg>({
      query: ({invoiceId, ...body}) => ({
        url: `/dms/v1/invoice/payment/pay-cash/${invoiceId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoicePayment', invoiceId: queryArg.invoiceId},
        {type: 'invoice', invoiceId: queryArg.invoiceId},
      ],
    }),
    payInvoiceWithCashReceipt: build.mutation<PayWithCashReceiptRes, PayWithCashReceiptArg>({
      query: ({invoiceId, ...body}) => ({
        url: `/dms/v1/invoice/payment/pay-cash-receipt/${invoiceId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'invoicePayment', invoiceId: queryArg.invoiceId},
        {type: 'invoice', invoiceId: queryArg.invoiceId},
      ],
    }),

    getPaymentTypeEnum: build.query<GetPaymentTypeEnumApiResponse, void>({
      query: () => ({
        url: `/dms/v1/rounding-definition/payment-type`,
      }),
    }),
    getRoundStep: build.query<GetRoundStepApiResponse, void>({
      query: () => ({
        url: `/dms/v1/rounding-definition/round-step`,
      }),
    }),
    invoicingDocumentRecalculate: build.mutation<
      AccountingDocumentRecalculateApiRes,
      AccountingDocumentRecalculateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/invoicing-document-items/recalculate`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    taxDocumentCalculationV2: build.mutation<
      TaxDocumentCalculationV2ApiResponse,
      TaxDocumentCalculationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/tax-document-calculation`,
        method: 'POST',
        body: queryArg.taxDocumentCalculationRequestBody,
      }),
    }),
    createVatCheckTotalsDefinition: build.mutation<
      CreateVatCheckTotalsDefinitionApiResponse,
      CreateVatCheckTotalsDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vat-check-totals-definition`,
        method: 'POST',
        body: queryArg.vatCheckTotalsDefinitionRequestBody,
      }),
      invalidatesTags: ['VatCheckTotalsDefinition'],
    }),
    getVatCalculationDefinition: build.query<
      GetVatCalculationDefinitionApiResponse,
      GetVatCalculationDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vat-calculation-definition/${queryArg}`,
      }),
    }),
    postNegativeDocumentCancellation: build.mutation<
      PostNegativeDocumentCancellationApiRes,
      PostNegativeDocumentCancellationApiArg
    >({
      query: (body) => ({
        url: `/dms/v1/accounting/negative-document-cancellation-definition`,
        method: 'POST',
        body,
      }),
    }),
    updateNegativeDocumentCancellation: build.mutation<
      UpdateNegativeDocumentCancellationApiRes,
      UpdateNegativeDocumentCancellationApiArg
    >({
      query: ({id, ...body}) => ({
        url: `/dms/v1/accounting/negative-document-cancellation-definition/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'cancellationDefinition', definitionId: queryArg.id},
      ],
    }),
    getNegativeDocumentCancellation: build.query<
      GetNegativeDocumentCancellationApiRes,
      GetNegativeDocumentCancellationApiArg
    >({
      query: ({id}) => ({
        url: `/dms/v1/accounting/negative-document-cancellation-definition/${id}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'cancellationDefinition', definitionId: queryArg.id},
      ],
    }),
    deleteNegativeDocumentCancellation: build.mutation<
      DeleteNegativeDocumentCancellationApiRes,
      DeleteNegativeDocumentCancellationApiArg
    >({
      query: ({id}) => ({
        url: `/dms/v1/accounting/negative-document-cancellation-definition/${id}`,
        method: 'DELETE',
      }),
    }),
    createInvoiceMargin: build.mutation<CreateInvoiceMarginApiRes, CreateInvoiceMarginApiArg>({
      query: (body) => ({
        url: '/dms/v2/accounting/margin-invoice-setting',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['marginDefinitions'],
    }),
    updateInvoiceMargin: build.mutation<EditInvoiceMarginApiRes, EditInvoiceMarginApiArg>({
      query: ({id, ...body}) => ({
        url: `/dms/v2/accounting/margin-invoice-setting/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['marginDefinitions'],
    }),
    deleteInvoiceMargin: build.mutation<DeleteInvoiceMarginApiRes, DeleteInvoiceMarginApiArg>({
      query: ({id}) => ({
        url: `/dms/v1/accounting/margin-invoice-setting/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['marginDefinitions'],
    }),
    getInvoiceMargin: build.query<GetInvoiceMarginApiRes, GetInvoiceMarginApiArg>({
      query: ({id}) => ({
        url: `/dms/v1/accounting/margin-invoice-setting/${id}`,
      }),
      keepUnusedDataFor: 0,
    }),
    getInvoiceMargins: build.query<GetInvoiceMarginsApiRes, void>({
      query: () => ({
        url: '/dms/v1/accounting/margin-invoice-settings',
      }),
      providesTags: ['marginDefinitions'],
    }),
    postProformaGeneration: build.mutation<
      PostProformaGenerationApiRes,
      PostProformaGenerationApiArg
    >({
      query: (body) => ({
        url: '/dms/v1/accounting/business-case/proforma-invoice/setting',
        method: 'POST',
        body,
      }),
    }),
    getProformaGeneration: build.query<GetProformaGenerationApiRes, GetProformaGenerationApiArg>({
      query: ({id}) => ({
        url: `/dms/v1/accounting/business-case/proforma-invoice/setting/${id}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'proformaGeneration', definitionId: queryArg.id},
      ],
    }),
    putProformaGeneration: build.mutation<PutProformaGenerationApiRes, PutProformaGenerationApiArg>(
      {
        query: ({id, ...body}) => ({
          url: `/dms/v1/accounting/business-case/proforma-invoice/setting/${id}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: (result, error, queryArg) => [
          {type: 'proformaGeneration', definitionId: queryArg.id},
        ],
      }
    ),
    deleteProformaGeneration: build.mutation<
      DeleteProformaGenerationApiRes,
      DeleteProformaGenerationApiArg
    >({
      query: ({id}) => ({
        url: `/dms/v1/accounting/business-case/proforma-invoice/setting/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'proformaGeneration', definitionId: queryArg.id},
      ],
    }),
    getBalanceInvoiceByInvoiceProformaId: build.query<
      GetBalanceInvoiceByInvoiceProformaIdApiResponse,
      GetBalanceInvoiceByInvoiceProformaIdApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/balance-invoice/invoice-proforma/${queryArg.invoiceProformaId}`,
      }),
    }),
    getBalanceInvoiceByInvoiceProformaIdV4: build.query<
      GetBalanceInvoiceByInvoiceProformaIdV4ApiResponse,
      GetBalanceInvoiceByInvoiceProformaIdV4ApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v4/balance-invoice/invoice-proforma/${queryArg.invoiceProformaId}`,
      }),
    }),
    getBalanceInvoice: build.query<GetBalanceInvoiceApiResponse, GetBalanceInvoiceApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/balance-invoice/${queryArg.balanceInvoiceId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'invoice', invoiceId: queryArg.balanceInvoiceId},
      ],
    }),
    getBalanceInvoiceV4: build.query<GetBalanceInvoiceV4ApiResponse, GetBalanceInvoiceV4ApiArg>({
      query: (queryArg) => ({
        url: `/dms/v4/balance-invoice/${queryArg.balanceInvoiceId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'invoice', invoiceId: queryArg.balanceInvoiceId},
      ],
    }),
    createCorrectiveTaxDocumentV4: build.mutation<
      CreateCorrectiveTaxDocumentV4ApiResponse,
      CreateCorrectiveTaxDocumentV4ApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v4/corrective-tax-document`,
        method: 'POST',
        body: queryArg.correctiveTaxDocumentV4RequestBody,
      }),
    }),
    getCorrectiveTaxDocumentV4: build.query<
      GetCorrectiveTaxDocumentV4ApiResponse,
      GetCorrectiveTaxDocumentV4ApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v4/corrective-tax-document/${queryArg.correctiveTaxDocumentId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'correctiveTaxDocument', correctiveTaxDocumentId: queryArg.correctiveTaxDocumentId},
      ],
    }),
    createInvoiceV4: build.mutation<CreateInvoiceV4ApiResponse, CreateInvoiceV4ApiArg>({
      query: (queryArg) => ({
        url: `/dms/v4/invoice`,
        method: 'POST',
        body: queryArg.invoiceV4RequestBody,
      }),
    }),
    getInvoiceV4: build.query<GetInvoiceV4ApiResponse, GetInvoiceV4ApiArg>({
      query: (queryArg) => ({
        url: `/dms/v4/invoice/${queryArg.invoiceId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'invoice', invoiceId: queryArg.invoiceId}],
      keepUnusedDataFor: 0,
    }),
    createInvoiceDraft: build.mutation<CreateInvoiceDraftApiResponse, CreateInvoiceDraftApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/invoice-draft`,
        method: 'POST',
        body: queryArg.invoiceDraftV2RequestBody,
      }),
    }),
    getInvoiceDraft: build.query<GetInvoiceDraftApiResponse, GetInvoiceDraftApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/invoice-draft/${queryArg.invoiceDraftId}`,
      }),
      keepUnusedDataFor: 0,
    }),
    updateInvoiceDraft: build.mutation<UpdateInvoiceDraftApiResponse, UpdateInvoiceDraftApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/invoice-draft/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.invoiceDraftV2RequestBody,
      }),
    }),
    createInvoiceProformaV4: build.mutation<
      CreateInvoiceProformaV4ApiResponse,
      CreateInvoiceProformaV4ApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v4/invoice-proforma`,
        method: 'POST',
        body: queryArg.invoiceProformaV4RequestBody,
      }),
    }),
    getInvoiceProformaV4: build.query<GetInvoiceProformaV4ApiResponse, GetInvoiceProformaV4ApiArg>({
      query: (queryArg) => ({
        url: `/dms/v4/invoice-proforma/${queryArg.invoiceProformaId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'invoiceProforma', invoiceId: queryArg.invoiceProformaId},
      ],
    }),
    createDiscountItem: build.mutation<CreateDiscountItemApiResponse, CreateDiscountItemApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/invoicing-document-item/create-discount`,
        method: 'POST',
        body: queryArg.createInvoicingItemDiscountRequestBody,
      }),
    }),
    createMarginItem: build.mutation<CreateMarginItemApiResponse, CreateMarginItemApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/invoicing-document-item/create-margin`,
        method: 'POST',
        body: queryArg.createInvoicingItemMarginRequestBody,
      }),
    }),
    createStandardItem: build.mutation<CreateStandardItemApiResponse, CreateStandardItemApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/invoicing-document-item/create-standard`,
        method: 'POST',
        body: queryArg.createInvoicingItemStandardRequestBody,
      }),
    }),
    createStandardMultipleItems: build.mutation<
      CreateStandardMultipleItemsApiResponse,
      CreateStandardMultipleItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/invoicing-document-item/create-standard-multiple`,
        method: 'POST',
        body: queryArg.createInvoicingItemsStandardRequestBodies,
      }),
    }),
    getTaxDocumentForPaymentV4: build.query<
      GetTaxDocumentForPaymentV4ApiResponse,
      GetTaxDocumentForPaymentV4ApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v4/tax-document-for-payment/${queryArg.id}`,
      }),
    }),
    calculatePercentage: build.query<GetCalculatePercentageApiRes, GetCalculatePercentageApiArg>({
      query: (params) => ({
        url: `/dms/v1/discount/calculate/percentage`,
        params,
      }),
    }),
    calculateByPercentage: build.query<
      GetCalculateByPercentageApiRes,
      GetCalculateByPercentageApiArg
    >({
      query: (params) => ({
        url: `/dms/v1/discount/calculate/by-percentage`,
        params,
      }),
    }),
    payCorrectiveTaxDocumentWithBankTransfer: build.mutation<
      PayWithBankTransferApiResponse,
      PayWithBankTransferApiArg
    >({
      query: ({correctiveTaxDocumentId, ...body}) => ({
        url: `/dms/v1/corrective-tax-document/payment/pay-bank-transfer/${correctiveTaxDocumentId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'correctiveTaxDocument', correctiveTaxDocumentId: queryArg.correctiveTaxDocumentId},
        'paymentPrescription',
      ],
    }),
    payCorrectiveTaxDocumentWithCardAndCreateReceipt: build.mutation<
      PayWithCardAndCreateReceiptApiResponse,
      PayWithCardAndCreateReceiptApiArg
    >({
      query: ({correctiveTaxDocumentId, ...body}) => ({
        url: `/dms/v1/corrective-tax-document/payment/pay-card-receipt/${correctiveTaxDocumentId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'correctiveTaxDocument', correctiveTaxDocumentId: queryArg.correctiveTaxDocumentId},
        'paymentPrescription',
      ],
    }),
    payCorrectiveTaxDocumentWithCard: build.mutation<PayWithCardApiResponse, PayWithCardApiArg>({
      query: ({correctiveTaxDocumentId, ...body}) => ({
        url: `/dms/v1/corrective-tax-document/payment/pay-card/${correctiveTaxDocumentId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'correctiveTaxDocument', correctiveTaxDocumentId: queryArg.correctiveTaxDocumentId},
        'paymentPrescription',
      ],
    }),
    payCorrectiveTaxDocumentWithCashAndCreateReceipt: build.mutation<
      PayWithCashAndCreateReceiptApiResponse,
      PayWithCashAndCreateReceiptApiArg
    >({
      query: ({correctiveTaxDocumentId, ...body}) => ({
        url: `/dms/v1/corrective-tax-document/payment/pay-cash-receipt/${correctiveTaxDocumentId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'correctiveTaxDocument', correctiveTaxDocumentId: queryArg.correctiveTaxDocumentId},
        'paymentPrescription',
      ],
    }),
    payCorrectiveTaxDocumentWithCash: build.mutation<PayWithCashApiResponse, PayWithCashApiArg>({
      query: ({correctiveTaxDocumentId, ...body}) => ({
        url: `/dms/v1/corrective-tax-document/payment/pay-cash/${correctiveTaxDocumentId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'correctiveTaxDocument', correctiveTaxDocumentId: queryArg.correctiveTaxDocumentId},
        'paymentPrescription',
      ],
    }),
    createTaxDocumentSetting: build.mutation<
      CreateTaxDocumentSettingApiResponse,
      CreateTaxDocumentSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/tax-document-setting`,
        method: 'POST',
        body: queryArg.taxDocumentSettingRequestBody,
      }),
      invalidatesTags: ['TaxDocumentSettingList'],
    }),
    deleteTaxDocumentSetting: build.mutation<
      DeleteTaxDocumentSettingApiResponse,
      DeleteTaxDocumentSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/tax-document-setting/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TaxDocumentSettingList'],
    }),
    getTaxDocumentSetting: build.query<
      GetTaxDocumentSettingApiResponse,
      GetTaxDocumentSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/tax-document-setting/${queryArg.id}`,
      }),
      keepUnusedDataFor: 0,
    }),
    getTaxDocumentSettingList: build.query<
      GetTaxDocumentSettingListApiResponse,
      GetTaxDocumentSettingListApiArg
    >({
      query: () => ({
        url: `/dms/v1/accounting/tax-document-setting/list`,
      }),
      providesTags: ['TaxDocumentSettingList'],
    }),
    updateTaxDocumentSetting: build.mutation<
      UpdateTaxDocumentSettingApiResponse,
      UpdateTaxDocumentSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/tax-document-setting/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateTaxDocumentSettingRequestBody,
      }),
      invalidatesTags: ['TaxDocumentSettingList'],
    }),
    createVatTotalAndCalculationSetting: build.mutation<
      CreateVatTotalAndCalculationSettingApiResponse,
      CreateVatTotalAndCalculationSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vat-total-and-calculation`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    deleteVatTotalAndCalculationSetting: build.mutation<
      DeleteVatTotalAndCalculationSettingApiResponse,
      DeleteVatTotalAndCalculationSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vat-total-and-calculation/${queryArg.vatTotalAndCalculationId}`,
        method: 'DELETE',
      }),
    }),
    getVatTotalAndCalculationSetting: build.query<
      GetVatTotalAndCalculationSettingApiResponse,
      GetVatTotalAndCalculationSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vat-total-and-calculation/${queryArg.vatTotalAndCalculationId}`,
      }),
      keepUnusedDataFor: 0,
    }),
    updateVatTotalAndCalculationSetting: build.mutation<
      UpdateVatTotalAndCalculationSettingApiResponse,
      UpdateVatTotalAndCalculationSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vat-total-and-calculation/${queryArg.vatTotalAndCalculationId}`,
        method: 'PUT',
        body: queryArg,
      }),
    }),
    createPreAccountingProcessingSettings: build.mutation<
      CreatePreAccountingProcessingSettingsApiResponse,
      CreatePreAccountingProcessingSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/pre-accounting-processing-setting`,
        method: 'POST',
        body: queryArg.createPreAccountingProcessingSettingsRequestBody,
      }),
      invalidatesTags: (result) => [{type: 'preAccountingProcessing', processingId: result?.id}],
    }),
    deletePreAccountingProcessingSettings: build.mutation<
      DeletePreAccountingProcessingSettingsApiResponse,
      DeletePreAccountingProcessingSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/pre-accounting-processing-setting/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'preAccountingProcessing', processingId: queryArg.id},
      ],
    }),
    getPreAccountingProcessingSettings: build.query<
      GetPreAccountingProcessingSettingsApiResponse,
      GetPreAccountingProcessingSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/pre-accounting-processing-setting/${queryArg.id}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'preAccountingProcessing', processingId: queryArg.id},
      ],
    }),
    updatePreAccountingProcessingSettings: build.mutation<
      UpdatePreAccountingProcessingSettingsApiResponse,
      UpdatePreAccountingProcessingSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/pre-accounting-processing-setting/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updatePreAccountingProcessingSettingsRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'preAccountingProcessing', processingId: queryArg.id},
      ],
    }),
    createCorrectiveTaxDocumentSetting: build.mutation<
      CreateCorrectiveTaxDocumentSettingApiResponse,
      CreateCorrectiveTaxDocumentSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/corrective-tax-document-setting`,
        method: 'POST',
        body: queryArg.createCorrectiveTaxDocumentSettingRequestBody,
      }),
    }),
    deleteCorrectiveTaxDocumentSetting: build.mutation<
      DeleteCorrectiveTaxDocumentSettingApiResponse,
      DeleteCorrectiveTaxDocumentSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/corrective-tax-document-setting/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getCorrectiveTaxDocumentSetting: build.query<
      GetCorrectiveTaxDocumentSettingApiResponse,
      GetCorrectiveTaxDocumentSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/corrective-tax-document-setting/${queryArg.id}`,
      }),
      providesTags: (_result, _error, queryArg) => [
        {type: 'correctiveDocumentSetting', id: queryArg.id},
      ],
    }),
    updateCorrectiveTaxDocumentSetting: build.mutation<
      UpdateCorrectiveTaxDocumentSettingApiResponse,
      UpdateCorrectiveTaxDocumentSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/accounting/corrective-tax-document-setting/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateCorrectiveTaxDocumentSettingRequestBody,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'correctiveDocumentSetting', id: queryArg.id},
      ],
    }),
  }),
});

export const {
  useGetPreAccountingProcessingSettingsQuery,
  useCreatePreAccountingProcessingSettingsMutation,
  useDeletePreAccountingProcessingSettingsMutation,
  useUpdatePreAccountingProcessingSettingsMutation,
  useUpdateVatTotalAndCalculationSettingMutation,
  useGetVatTotalAndCalculationSettingQuery,
  useDeleteVatTotalAndCalculationSettingMutation,
  useCreateVatTotalAndCalculationSettingMutation,
  useCreateTaxDocumentSettingMutation,
  useDeleteTaxDocumentSettingMutation,
  useGetTaxDocumentSettingQuery,
  useGetTaxDocumentSettingListQuery,
  useCreateManuallyTaxDocumentForPaymentMutation,
  useUpdateTaxDocumentSettingMutation,
  useGetCorrectiveTaxDocumentPaymentListQuery,
  usePayCorrectiveTaxDocumentWithBankTransferMutation,
  usePayCorrectiveTaxDocumentWithCardAndCreateReceiptMutation,
  usePayCorrectiveTaxDocumentWithCardMutation,
  usePayCorrectiveTaxDocumentWithCashAndCreateReceiptMutation,
  usePayCorrectiveTaxDocumentWithCashMutation,
  useGetTenantRegisterListQuery,
  useGetBalanceInvoiceV4Query,
  useCreateInvoiceV4Mutation,
  useCreateInvoiceDraftMutation,
  useUpdateInvoiceDraftMutation,
  useLazyCalculatePercentageQuery,
  useDeleteProformaGenerationMutation,
  usePostProformaGenerationMutation,
  usePutProformaGenerationMutation,
  useGetProformaGenerationQuery,
  useGetInvoiceMarginQuery,
  useGetInvoiceMarginsQuery,
  useDeleteInvoiceMarginMutation,
  useUpdateInvoiceMarginMutation,
  useCreateInvoiceMarginMutation,
  useGetCashRegisterDocumentKindOfPaymentListQuery,
  useCreateCashRegisterMutation,
  useGetCashRegisterListQuery,
  useGetNegativeDocumentCancellationQuery,
  useGetCashRegisterQuery,
  useDeleteNegativeDocumentCancellationMutation,
  usePatchCashRegisterMutation,
  useInactivateCashRegisterMutation,
  useCancelCashRegisterDocumentMutation,
  usePostNegativeDocumentCancellationMutation,
  useUpdateNegativeDocumentCancellationMutation,
  useCreateCashReceiptV4Mutation,
  useGetCashReceiptV4Query,
  useCreateDocumentLimitDefinitionMutation,
  useGetDocumentLimitDefinitionListQuery,
  useGetDocumentLimitDefinitionQuery,
  useRemoveDocumentLimitDefinitionMutation,
  useUpdateDocumentLimitDefinitionMutation,
  useGetDocumentRestrictionQuery,
  useCancelCorrectiveTaxDocumentMutation,
  useCreateCorrectiveTaxDocumentV4Mutation,
  useGetCorrectiveTaxDocumentV4Query,
  useSendCorrectiveTaxDocumentMutation,
  useRenderBalanceInvoiceDocumentMutation,
  useRenderCorrectiveTaxDocumentMutation,
  useRenderExpenseCashReceiptDocumentMutation,
  useRenderIncomeCashReceiptDocumentMutation,
  useRenderInvoiceDocumentMutation,
  useRenderInvoiceProformaDocumentMutation,
  useRenderTaxDocumentForPaymentDocumentMutation,
  useCancelInvoiceMutation,
  useCreateDocumentSeriesDefinitionMutation,
  useGetDocumentSeriesDefinitionListQuery,
  useDeleteDocumentSeriesDefinitionMutation,
  useUpdateDocumentSeriesDefinitionMutation,
  useGetInvoiceV4Query,
  useLazyGetInvoiceV4Query,
  useGetInvoicePaymentListQuery,
  useSendInvoiceMutation,
  useDeleteInvoiceDraftMutation,
  useGetInvoiceDraftQuery,
  useGetInvoiceProformaV4Query,
  useGetInvoiceProformaPaymentListQuery,
  useCreateCheckoutCorrectiveTaxDocumentMutation,
  useGetPaymentTypesForInvoicePaymentQuery,
  useCreateRoundingDefinitionMutation,
  useGetRoundingDefinitionListQuery,
  useDeleteRoundingDefinitionMutation,
  useTaxDocumentCalculationV2Mutation,
  useInvoicingDocumentRecalculateMutation,
  useGetRoundingDefinitionQuery,
  useUpdateRoundingDefinitionMutation,
  useReplaceInvoiceCustomerMutation,
  useGetPaymentTypeEnumQuery,
  useGetRoundStepQuery,
  useGetTaxDocumentForPaymentV4Query,
  useCancelInvoiceProformaMutation,
  usePayInvoiceWithBankTransferMutation,
  usePayInvoiceWithCardMutation,
  usePayInvoiceWithCardReceiptMutation,
  usePayInvoiceWithCashMutation,
  usePayInvoiceWithCashReceiptMutation,
  useCreateCorrectiveTaxDocumentSettingMutation,
  useDeleteCorrectiveTaxDocumentSettingMutation,
  useGetCorrectiveTaxDocumentSettingQuery,
  useUpdateCorrectiveTaxDocumentSettingMutation,
} = accountingApi;
