import {z} from 'zod';

import {PaymentTypeSchema} from '@dms/api';

import {zodNumber, zodString} from 'shared';

export const DeliveryNoteFormSchema = z.object({
  supplierId: zodString.required(),
  warehouseId: zodString.required(),
  authorizationProfileId: zodString.required(),
  invoiceNumber: zodString,
  paymentType: PaymentTypeSchema,
  currency: zodString.required(),
  exchangeRate: zodNumber,
  ncConversion: zodString,
});

export type DeliveryNoteForm = z.infer<typeof DeliveryNoteFormSchema>;
