import {FormProps} from 'platform/components';

import {GetTireSetApiResponse, PhoneNumber} from '@dms/api';

import {parseDate, getApiDateString} from 'shared';

import {TireSetFormType} from '../../../types/tires/TireSetFormType';

export const getEditTireSetDefaultValues = (
  tenantPhoneInfo: PhoneNumber,
  tireSet: GetTireSetApiResponse
): FormProps<TireSetFormType>['defaultValues'] => ({
  contactPerson: {
    name: tireSet?.contactPerson?.name ?? undefined,
    email: tireSet?.contactPerson?.email ?? undefined,
    phoneNumber: {
      number: tireSet?.contactPerson?.phoneNumber?.number ?? undefined,
      prefix: tireSet?.contactPerson?.phoneNumber?.prefix ?? tenantPhoneInfo.prefix,
      countryCode: tireSet?.contactPerson?.phoneNumber?.countryCode ?? tenantPhoneInfo.countryCode,
    },
  },
  assignMechanic: tireSet?.assignMechanic ?? undefined,
  storedLocation: tireSet?.storedLocation ?? undefined,
  storedUntil: tireSet?.storedUntil ? getApiDateString(parseDate(tireSet?.storedUntil)) : undefined,
  numberOfTires: tireSet?.wheels?.length ?? undefined,
  note: tireSet?.note ?? undefined,
  wheels:
    tireSet?.wheels?.map((wheel) => ({
      checked: true,
      id: wheel?.id ?? undefined,
      aspectRatio: wheel?.wheelDetails?.aspectRatio ?? undefined,
      dot: wheel?.wheelDetails?.dot ?? undefined,
      li: wheel?.wheelDetails?.li ?? undefined,
      location: wheel?.wheelDetails?.location ?? undefined,
      manufacturer: wheel?.wheelDetails?.manufacturer ?? undefined,
      note: wheel?.wheelDetails?.note ?? undefined,
      rim: wheel?.wheelDetails?.rim ?? undefined,
      rimType: wheel?.wheelDetails?.rimType ?? undefined,
      season: wheel?.wheelDetails?.season ?? undefined,
      sr: wheel?.wheelDetails?.sr ?? undefined,
      tireWidth: wheel?.wheelDetails?.tireWidth ?? undefined,
      tread: wheel?.wheelDetails?.tread ?? undefined,
    })) ?? [],
});
