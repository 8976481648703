import {z} from 'zod';

import {AvailableDiscountSchema, DiscountSourceTypeSchema} from '@dms/api';

import {zodNumber, zodString} from 'shared';

export const LabourMechanicSchema = z.object({
  id: zodString,
  amount: zodNumber,
  ratio: zodNumber,
  costCenterId: zodString,
});

export type LabourMechanic = z.infer<typeof LabourMechanicSchema>;

export const EditLabourItemFormSchema = z.object({
  name: zodString.required(),
  number: zodString.required(),
  workType: zodString.required(),
  assignMechanics: z.array(LabourMechanicSchema),
  quantity: zodNumber.positive().required(),
  purchasePricePerUnit: zodNumber,
  sellingPricePerUnit: zodNumber.required(),
  vatType: zodString.required(),
  vatFreeCode: zodString,
  isUnitPriceWithVat: z.boolean().default(false),
  isCustomPrice: z.boolean().default(false),
  discount: z.object({
    isDoNotApplyDiscount: z.boolean().default(false),
    isDiscountEnable: z.boolean().default(false),
    discountSourceType: DiscountSourceTypeSchema.nullish(),
    discountRate: zodNumber,
    availableDiscounts: z.array(
      AvailableDiscountSchema.extend({discountRate: zodNumber.minMax(0, 100)})
    ),
  }),
  // Used only locally to be able to control the getCurrentPrice action recalculations
  shouldGetCurrentPrice: z.boolean().default(false),
});

export type EditLabourItemForm = z.infer<typeof EditLabourItemFormSchema>;
