import {Action, openDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';

import {head, isNil} from 'ramda';
import {isArray, isString} from 'ramda-adjunct';

import {
  ConvertForAllVehiclesApiArg,
  UpdateInterestApiArg,
  useConvertForAllVehiclesMutation,
  useUpdateInterestMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {interestRoutes, testIds} from '@dms/routes';
import {
  handleApiError,
  InterestEndCaseDialog,
  interestSearchParams,
  Main,
  queryParams,
  usePermissions,
} from '@dms/shared';

import {buildArray, composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

/**
 * @description action definition for interest dataGrid
 */
type InterestData = {
  customer: {
    value: {
      id: string;
      label: string;
    };
  };
  id: string;
};

export function InterestOverview() {
  const navigate = useNavigate();

  const [updateInterest] = useUpdateInterestMutation();
  const [convertForAllVehicles] = useConvertForAllVehiclesMutation();

  const [canCreateInterest] = usePermissions({
    permissionKeys: ['interestCreate'],
    scopes: {
      interestCreate: {participation: undefined},
    },
  });

  const actionCallback: ActionCallback = ({
    actionKey,
    rowId,
    rowData,
    sourceSearchParams,
    refreshData,
  }) => {
    const data = isArray(rowData) ? head(rowData) : rowData;

    if (isNil(rowData) || !isString(rowId)) {
      showNotification.error();
      return;
    }

    match(actionKey)
      .with('detail', () =>
        navigate(
          composePath(interestRoutes.detail, {
            params: {id: rowId},
            isSearchParamsPreserved: false,
            queryParams: `${sourceSearchParams}&${[queryParams.COMPONENT_SECTIONS_SECTION]}=${interestSearchParams.interestDetail.overview}`,
          })
        )
      )
      .with('detail_newTab', () => {
        window.open(
          composePath(interestRoutes.detail, {
            params: {id: rowId},
            isSearchParamsPreserved: false,
            queryParams: `${sourceSearchParams}&${[queryParams.COMPONENT_SECTIONS_SECTION]}=${interestSearchParams.interestDetail.overview}`,
          })
        );
      })
      .with('open', () => {
        const args: UpdateInterestApiArg = {
          id: rowId,
          updateInterestRequestBody: {
            state: 'OPEN',
          },
        };
        updateInterest(args)
          .unwrap()
          .then(() => refreshData())
          .catch(handleApiError);
      })
      .with('end', () =>
        openDialog(
          <InterestEndCaseDialog
            data-testid={testIds.interest.overview('endCaseDialog')}
            interestId={rowId}
            onSubmit={refreshData}
          />,
          {
            'data-testid': testIds.interest.overview('endCaseDialog'),
            title: i18n.t('entity.interest.labels.endInterestCase'),
            scrollBehavior: 'outside',
          }
        )
      )
      .with('createBusinessCase', () => {
        const args: ConvertForAllVehiclesApiArg = {
          interestId: (data as InterestData).id ?? '',
        };
        convertForAllVehicles(args)
          .unwrap()
          .then(() => {
            refreshData();
            showNotification.success();
          })
          .catch(handleApiError);
      })
      .otherwise(() => {
        showNotification.warning(`Action callback was not specified for action ${actionKey}`);
      });
  };

  const actions: Action[] = buildArray<Action>([]).when(canCreateInterest, {
    type: 'button',
    onClick: () => navigate(interestRoutes.create),
    variant: 'primary',
    'data-testid': testIds.interest.overview('create'),
    title: i18n.t('entity.interest.actions.newInterest'),
  });

  return (
    <>
      <Helmet title={i18n.t('page.interests.title')} />
      <Main isFullHeight actions={actions} data-testid={testIds.interest.overview('container')}>
        <DataGrid
          data-testid={testIds.interest.overview('dataGrid')}
          gridCode="interest"
          actionCallback={actionCallback}
        />
      </Main>
    </>
  );
}
