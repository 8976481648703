import {
  AttributesRow,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Hide, HStack, Show} from 'platform/foundation';

import {useCallback, useState} from 'react';

import {defaultTo, isNil, isNotNil, mergeAll} from 'ramda';
import {isString} from 'ramda-adjunct';

import {
  PatchReservationDirectSalesRequest,
  PostReservationDirectSalesRequest,
  useGetCustomerV2Query,
  useGetReservationDirectSalesQuery,
  useGetVehicleQuery,
  usePatchReservationDirectSalesMutation,
  usePostReservationDirectSalesMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {
  getCustomerName,
  handleApiError,
  isCustomerNaturalPerson,
  useAddress,
  useDuplicateErrorHandler,
  useVehicleCatalogue,
} from '@dms/shared';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

import {DataGrid, QueryFilterObject, RowData} from 'features/datagrid';

import {reservationFormSchema} from '../../../../../constants/reservationFormSchema';
import {getDefaultRequestExpiresAtTime} from '../../../../../utils/getDefaultRequestExpiresAtTime';
import {getRequestExpiresAtDate} from '../../../../../utils/getRequestExpiresAtDate';
import {DirectSaleDetails} from './DirectSaleDetails';
import {ReservationFooter} from './ReservationFooter';
import {ReservationForm, ReservationInfo} from './ReservationInfo';

type DirectSaleId = {
  directSaleId: string;
};

interface ReservationDirectSalesProps extends TestIdProps {
  articleId: string;
  dispensingUnit: number;
  onSubmit: () => void;
  reservationItemId?: string;
  cancelAndDeleteConfirmText?: string;
  onCancel?: (id: string) => void;
  onCancelAndDelete?: (id: string) => void;
}

export function ReservationDirectSales(props: ReservationDirectSalesProps) {
  const {duplicateError, duplicateErrorHandler} = useDuplicateErrorHandler();
  const {composeAddress} = useAddress();

  const [selectedRows, setSelectedRows] = useState<DirectSaleId[]>([]);

  const {
    data: reservation,
    isLoading: isReservationLoading,
    isError: hasReservationError,
  } = useGetReservationDirectSalesQuery(
    {articleId: props.articleId, requestId: props.reservationItemId},
    {skip: isNil(props.reservationItemId)}
  );

  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: hasVehicleError,
  } = useGetVehicleQuery(
    {vehicleId: defaultTo('', reservation?.vehicleId)},
    {skip: isNil(reservation?.vehicleId)}
  );

  const [vehicleUtils] = useVehicleCatalogue(vehicle?.type);

  const {
    data: customer,
    isLoading: isCustomerLoading,
    isError: hasCustomerError,
  } = useGetCustomerV2Query(
    {customerId: defaultTo('', reservation?.customerId)},
    {skip: isNil(reservation?.customerId)}
  );

  const [postReservation] = usePostReservationDirectSalesMutation();
  const [patchReservation] = usePatchReservationDirectSalesMutation();

  const isLoading = isReservationLoading || isCustomerLoading || isVehicleLoading;
  const isError = hasReservationError || hasCustomerError || hasVehicleError;

  const handleSubmit: FormSubmitHandler<ReservationForm> = async (data) => {
    if (isNotNil(props.reservationItemId)) {
      const reservationData: PatchReservationDirectSalesRequest['body'] = {
        requestExpiresAtDate: getRequestExpiresAtDate(
          data.requestExpiresAtDate,
          data.requestExpiresAtTime
        ),
        note: data.note,
      };
      return await patchReservation({
        articleId: props.articleId,
        requestId: props.reservationItemId,
        body: reservationData,
      })
        .unwrap()
        .then(() =>
          showNotification.success(i18n.t('entity.warehouse.notifications.reservationUpdated'))
        )
        .then(closeCurrentDialog)
        .then(props.onSubmit)
        .catch(handleApiError);
    }

    const reservationData: PostReservationDirectSalesRequest = {
      articleId: props.articleId,
      quantity: data.quantity,
      requestExpiresAtDate: getRequestExpiresAtDate(
        data.requestExpiresAtDate,
        data.requestExpiresAtTime
      ),
      note: data.note,
      directSales: selectedRows,
    };

    await postReservation(reservationData)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('entity.warehouse.notifications.reservationCreated'));
        setSelectedRows([]);
      })
      .then(closeCurrentDialog)
      .then(props.onSubmit)
      .catch(duplicateErrorHandler);
  };

  const handleRowsSelect = (rows: RowData[]) => {
    const mappedRows = extractDirectSaleId(rows);
    setSelectedRows(mappedRows);
  };

  const extractDirectSaleId = (rows: RowData[]) => {
    const result: DirectSaleId[] = [];

    rows.forEach((row) => {
      if ('id' in row && isString(row.id)) {
        const directSaleId = row.id;

        result.push({directSaleId});
      }
    });

    return result;
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {state: ['OPEN']}]),
    []
  );

  const vehicleType = vehicleUtils.getVehicleType(vehicle?.type);

  const customerName = getCustomerName(customer);

  const customerAddress =
    isNotNil(customer) && isCustomerNaturalPerson(customer)
      ? composeAddress(customer?.foundingPerson?.permanentAddress?.address)
      : composeAddress(customer?.businessInfo?.address?.address);

  const reservationDetails: AttributesRow[] = [
    {
      label: i18n.t('entity.warehouse.labels.directSaleNumber'),
      value: reservation?.directSale.number,
    },
    {
      label: i18n.t('entity.warehouse.labels.directSaleType'),
      value: reservation?.directSale.type,
    },
    {
      label: i18n.t('entity.warehouse.labels.vehicleType'),
      value: vehicleType,
    },
    {
      label: i18n.t('entity.warehouse.labels.vehicle'),
      value: vehicle?.title,
    },
    {
      label: i18n.t('entity.warehouse.labels.vin'),
      value: vehicle?.vin,
    },
    {
      label: i18n.t('entity.warehouse.labels.licencePlate'),
      value: vehicle?.state?.registrationPlate,
    },
    {
      label: i18n.t('entity.warehouse.labels.customer'),
      value: customerName,
    },
    {
      label: i18n.t('entity.warehouse.labels.address'),
      value: customerAddress,
    },
  ];

  const defaultValues: ReservationForm = {
    quantity: reservation?.quantity ?? props.dispensingUnit,
    requestExpiresAtDate: reservation?.requestExpiresAtDate
      ? parseDate(reservation.requestExpiresAtDate)
      : null,
    requestExpiresAtTime: reservation?.requestExpiresAtDate
      ? getDefaultRequestExpiresAtTime(reservation.requestExpiresAtDate)
      : null,
    note: reservation?.note,
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<ReservationForm>
        defaultValues={defaultValues}
        experimentalZodSchema={reservationFormSchema(false, props.dispensingUnit)}
        onSubmit={handleSubmit}
      >
        {(control, formApi) => (
          <>
            <HStack>
              <Box flex={1}>
                <ReservationInfo
                  formApi={formApi}
                  control={control}
                  reservationItemId={props.reservationItemId}
                  dispensingUnit={props.dispensingUnit}
                  duplicateError={duplicateError}
                  isQuantityDisabled={isNotNil(props.reservationItemId)}
                  data-testid={suffixTestId('info', props)}
                />
              </Box>
              <Separator orientation="vertical" />
              <Box flex={2}>
                <Show when={isNil(props.reservationItemId)}>
                  <DataGrid
                    gridCode="direct-sale"
                    onRowSelectionChange={handleRowsSelect}
                    queryModifier={queryModifier}
                    data-testid={suffixTestId('directSales', props)}
                  />
                </Show>
                <Hide when={isNil(props.reservationItemId)}>
                  <DirectSaleDetails
                    directSaleId={defaultTo('', reservation?.directSale.directSaleId)}
                    rows={reservationDetails}
                    data-testid={suffixTestId('directSaleInformation', props)}
                  />
                </Hide>
              </Box>
            </HStack>
            <ReservationFooter
              control={control}
              reservationItemId={props.reservationItemId}
              cancelAndDeleteConfirmText={props.cancelAndDeleteConfirmText}
              onCancel={props.onCancel}
              onCancelAndDelete={props.onCancelAndDelete}
              data-testid={suffixTestId('footer', props)}
            />
          </>
        )}
      </Form>
    </DataStatus>
  );
}
