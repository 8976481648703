import {
  closeCurrentDialog,
  DataStatus,
  Form,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  PatchReservationOtherRequest,
  PostReservationOtherRequest,
  useGetReservationOtherQuery,
  usePatchReservationOtherMutation,
  usePostReservationOtherMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError, useDuplicateErrorHandler} from '@dms/shared';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

import {reservationFormSchema} from '../../../../../constants/reservationFormSchema';
import {getDefaultRequestExpiresAtTime} from '../../../../../utils/getDefaultRequestExpiresAtTime';
import {getRequestExpiresAtDate} from '../../../../../utils/getRequestExpiresAtDate';
import {ReservationFooter} from './ReservationFooter';
import {ReservationForm, ReservationInfo} from './ReservationInfo';

interface ReservationOtherProps extends TestIdProps {
  articleId: string;
  dispensingUnit: number;
  onSubmit: () => void;
  reservationItemId?: string;
  onCancel?: (id: string) => void;
}

export function ReservationOther(props: ReservationOtherProps) {
  const {duplicateError, duplicateErrorHandler} = useDuplicateErrorHandler();

  const {
    data: reservation,
    isLoading: isReservationLoading,
    isError: isReservationError,
  } = useGetReservationOtherQuery(
    {articleId: props.articleId, requestId: props.reservationItemId},
    {skip: isNil(props.reservationItemId)}
  );

  const [postReservation] = usePostReservationOtherMutation();
  const [patchReservation] = usePatchReservationOtherMutation();

  const handleSubmit: FormSubmitHandler<ReservationForm> = async (data) => {
    if (isNil(data.note)) {
      return;
    }

    if (isNotNil(props.reservationItemId)) {
      const reservationData: PatchReservationOtherRequest['body'] = {
        quantity: data.quantity,
        requestExpiresAtDate: getRequestExpiresAtDate(
          data.requestExpiresAtDate,
          data.requestExpiresAtTime
        ),
        note: data.note,
      };
      return await patchReservation({
        articleId: props.articleId,
        requestId: props.reservationItemId,
        body: reservationData,
      })
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('entity.warehouse.notifications.reservationUpdated'));
        })
        .then(closeCurrentDialog)
        .then(props.onSubmit)
        .catch(handleApiError);
    }

    const reservationData: PostReservationOtherRequest = {
      articleId: props.articleId,
      quantity: data.quantity,
      requestExpiresAtDate: getRequestExpiresAtDate(
        data.requestExpiresAtDate,
        data.requestExpiresAtTime
      ),
      note: data.note,
    };
    await postReservation(reservationData)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('entity.warehouse.notifications.reservationCreated'));
      })
      .then(closeCurrentDialog)
      .then(props.onSubmit)
      .catch(duplicateErrorHandler);
  };

  const defaultValues: Partial<ReservationForm> = {
    quantity: reservation?.quantity ?? props.dispensingUnit,
    requestExpiresAtDate: reservation?.requestExpiresAtDate
      ? parseDate(reservation.requestExpiresAtDate)
      : null,
    requestExpiresAtTime: reservation?.requestExpiresAtDate
      ? getDefaultRequestExpiresAtTime(reservation.requestExpiresAtDate)
      : null,
    note: reservation?.note,
  };

  return (
    <DataStatus isLoading={isReservationLoading} isError={isReservationError}>
      <Form<ReservationForm>
        defaultValues={defaultValues}
        experimentalZodSchema={reservationFormSchema(true, props.dispensingUnit)}
        onSubmit={handleSubmit}
      >
        {(control, formApi) => (
          <>
            <ReservationInfo
              formApi={formApi}
              control={control}
              reservationItemId={props.reservationItemId}
              dispensingUnit={props.dispensingUnit}
              duplicateError={duplicateError}
              isNoteRequired
              data-testid={suffixTestId('info', props)}
            />
            <ReservationFooter
              control={control}
              reservationItemId={props.reservationItemId}
              onCancel={props.onCancel}
              data-testid={suffixTestId('footer', props)}
            />
          </>
        )}
      </Form>
    </DataStatus>
  );
}
