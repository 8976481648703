import {BaseFlagProps} from 'platform/components';

import {isNil} from 'ramda';

import {CorrectiveTaxDocumentV4ResponseBodyRead} from '@dms/api';
import {i18n} from '@dms/i18n';

import {buildArray} from 'shared';

import {getDocumentSourceFlags} from './getDocumentSourceFlags';

export const getCorrectiveTaxDocumentSubFlags = (
  correctiveTaxDocument: CorrectiveTaxDocumentV4ResponseBodyRead | undefined
) => {
  if (isNil(correctiveTaxDocument?.isFull)) {
    return [];
  }

  return [
    ...getDocumentSourceFlags(correctiveTaxDocument?.documentSource),
    ...buildArray<BaseFlagProps>()
      .when(correctiveTaxDocument.isFull, () => ({
        colorScheme: 'blue',
        label: i18n.t('entity.correctiveTaxDocument.labels.full'),
      }))
      .whenNot(correctiveTaxDocument.isFull, () => ({
        colorScheme: 'blue',
        label: i18n.t('entity.correctiveTaxDocument.labels.partial'),
      })),
  ];
};
