import {
  Action,
  closeDialog,
  DataStatus,
  Form,
  FormControl,
  openConfirmDialog,
  openDialog,
  showNotification,
  TabProps,
  Tabs,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import {object} from 'yup';

import {useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {useDispatch} from 'react-redux';

import {head, isNil, mergeAll, not, reject} from 'ramda';
import {isArray, isNonEmptyArray, isNotNil, isTrue} from 'ramda-adjunct';

import {
  documentContextApi,
  useGetGeneralSettingsStorageLocationQuery,
  useGetTireSetQuery,
  useGetTireSetRequestQuery,
  usePatchTireSetMutation,
  usePostTireSetAddWheelsMutation,
  usePostTireSetCancelRequestMutation,
  usePostTireSetDeliveryNoteMutation,
  usePostTireSetLabelMutation,
  usePostTiresTransferToOrderMutation,
} from '@dms/api';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps, yupNumber} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {useTenant} from '../../hooks/useTenant';
import {useTireSetOptions} from '../../hooks/useTireSetOptions';
import {TireSetFormType} from '../../types/tires/TireSetFormType';
import {WheelType} from '../../types/tires/WheelType';
import {handleApiError} from '../../utils/handleApiError';
import {printFile} from '../../utils/printFile';
import {RequestTireSetForm} from '../RequestTireSetForm/RequestTireSetForm';
import {TireSetFormHeader} from '../TireSetFormHeader/TireSetFormHeader';
import {WheelArray} from '../WheelArray/WheelArray';
import {EditTireSetFooterButtons} from './components/EditTireSetFooterButtons';
import {getEditTireSetDefaultValues} from './utils/getEditTireSetDefaultValues';

interface EditTireSetProps extends TestIdProps {
  tireOrderId: string;
  setId: string;
  onClose: () => void;
  onEdit: () => void;
}

const TIRES_TAB_ID = 'tires';
const TIRES_MOVEMENT_TAB_ID = 'tireMovement';

export function EditTireSet(props: EditTireSetProps) {
  const dispatch = useDispatch();

  const [postTireSetDeliveryNote, {isLoading: isTiresSetDeliveryNoteLoading}] =
    usePostTireSetDeliveryNoteMutation();
  const [postTireSetLabel, {isLoading: isTiresSetLabelLoading}] = usePostTireSetLabelMutation();
  const [postTiresTransferToOrder] = usePostTiresTransferToOrderMutation();
  const {isLoading: isOptionsLoading} = useTireSetOptions();
  const {isLoading: isGeneralSettingsStorageLocationLoading} =
    useGetGeneralSettingsStorageLocationQuery();
  const [activeTab, setActiveTab] = useState(TIRES_TAB_ID);
  const {tenantPhoneInfo} = useTenant();

  const {
    data: tireSet,
    isLoading: isTireSetLoading,
    isError: isTireSetError,
  } = useGetTireSetQuery({
    orderId: props.tireOrderId,
    setId: props.setId,
  });
  const {data: tireSetRequest, isLoading: isTireSetRequestLoading} = useGetTireSetRequestQuery({
    orderId: props.tireOrderId,
    setId: props.setId,
  });
  const [patchTireSet] = usePatchTireSetMutation();
  const [postTireSetAddWheels] = usePostTireSetAddWheelsMutation();
  const [postTireSetCancelRequest] = usePostTireSetCancelRequestMutation();

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {orderId: props.tireOrderId, orderSetId: props.setId}]);

  const refreshDocumentCount = () =>
    dispatch(
      documentContextApi.util.invalidateTags([{type: 'documentsCount', id: props.tireOrderId}])
    );

  const saveTires = async (data: TireSetFormType) => {
    const {wheels, ...other} = data;

    const createdWheels = wheels.filter((wheel) => isNil(wheel.id));
    const editedWheels = wheels.filter((wheel) => isNotNil(wheel.id));

    const tireSetAddWheels =
      isNonEmptyArray(createdWheels) &&
      postTireSetAddWheels({
        setId: props.setId,
        orderId: props.tireOrderId,
        body: {
          ...other,
          wheels: createdWheels.map((wheelDetails, index) => ({
            number: String(editedWheels.length + index + 1),
            wheelDetails,
          })),
        },
      })
        .unwrap()
        .catch(handleApiError);

    const tireSet = patchTireSet({
      setId: props.setId,
      orderId: props.tireOrderId,
      body: {
        ...other,
        wheels: editedWheels.map(({id, ...wheelDetails}, index) => ({
          id,
          number: String(index + 1),
          wheelDetails,
        })),
      },
    })
      .unwrap()
      .catch(handleApiError);

    await Promise.all([tireSetAddWheels, tireSet]);
  };

  const handleSubmit = async (data: TireSetFormType) => {
    await saveTires(data);

    props.onClose();
    props.onEdit();
  };

  const hasDifferentWheelState = (state: string, wheels: WheelType[]) => {
    const wheelIds = wheels.filter((wheel) => isTrue(wheel.checked)).map(({id}) => id);

    const selectedWheels = tireSet?.wheels?.filter(
      (wheel) => !!wheel?.id && wheelIds.includes(wheel.id)
    );

    return not(selectedWheels?.every((wheel) => wheel?.state === state));
  };

  const isLoading =
    isOptionsLoading ||
    isTireSetLoading ||
    isGeneralSettingsStorageLocationLoading ||
    isTireSetRequestLoading;

  const isNumberOfWheelsDisabled = tireSet?.wheels?.some((wheel) => wheel?.state === 'INSTOCK');
  const hasArchivedWheel = tireSet?.wheels?.some((wheel) => wheel?.state === 'ARCHIVED');
  const hasPickedWheel = tireSet?.wheels?.some((wheel) => wheel?.state === 'PICKED');

  const handleTireSetRequest = () => {
    openDialog(
      <RequestTireSetForm
        orderId={props.tireOrderId}
        setId={props.setId}
        onClose={() => closeDialog('requestTireSet')}
        data-testid={suffixTestId('requestTireSet', props)}
      />,
      {
        id: 'requestTireSet',
        title: i18n.t('entity.tireSet.actions.tireSetRequest'),
        size: 'small',
      }
    );
  };

  const transferToOrder = () => {
    const transferActionCallback: ActionCallback = ({rowId}) => {
      const targetOrderId = isArray(rowId) ? head(rowId) : rowId;

      if (isNil(targetOrderId)) {
        return;
      }

      openConfirmDialog({
        text: i18n.t('page.tiresInventory.labels.transferToOrderQuestion'),
        onConfirm: () =>
          postTiresTransferToOrder({
            orderId: props.tireOrderId,
            setId: props.setId,
            body: {targetOrderId},
          })
            .unwrap()
            .then(() => {
              closeDialog('transferToOrder');
              props.onClose();
              props.onEdit();
            })
            .then(refreshDocumentCount)
            .catch(handleApiError),
      });
    };

    openDialog(
      <DataGrid
        gridCode="tire-set-transfer"
        autoHeight
        actionCallback={transferActionCallback}
        data-testid={suffixTestId('tireTransfer', props)}
      />,
      {
        title: i18n.t('page.tiresInventory.labels.transferToOrder'),
        size: 'large',
        id: 'transferToOrder',
      }
    );
  };

  const printLabel = (selectedWheelIds: string[]) => () => {
    postTireSetLabel({
      orderId: props.tireOrderId,
      setId: props.setId,
      body: {wheels: selectedWheelIds.map((id) => ({id}))},
    })
      .unwrap()
      .then((data) => {
        const url = data?.printout?.pdfUrl;
        if (url) {
          printFile(url);
        } else {
          showNotification.error();
        }
      })
      .then(refreshDocumentCount)
      .catch(handleApiError);
  };

  const printDeliveryNote = (selectedWheelIds: string[]) => () => {
    postTireSetDeliveryNote({
      orderId: props.tireOrderId,
      setId: props.setId,
      body: {wheels: selectedWheelIds.map((id) => ({id}))},
    })
      .unwrap()
      .then((data) => {
        const url = data?.printout?.pdfUrl;
        if (url) {
          printFile(url);
        } else {
          showNotification.error();
        }
      })
      .then(refreshDocumentCount)
      .catch(handleApiError);
  };

  const getTabs = (
    control: FormControl<TireSetFormType>,
    formApi: UseFormReturn<TireSetFormType, any>
  ): TabProps[] => {
    const selectedWheelIds = reject(
      isNil,
      formApi
        .getValues('wheels')
        .filter((wheel) => isTrue(wheel.checked))
        .map((wheel) => wheel.id)
    );

    const actions: Action[] = [
      {
        type: 'button',
        variant: 'secondary',
        title: tireSetRequest
          ? i18n.t('entity.tireSet.actions.editTireSetRequest')
          : i18n.t('entity.tireSet.actions.tireSetRequest'),
        isDisabled: hasArchivedWheel || formApi.formState.isDirty || hasPickedWheel,
        'data-testid': suffixTestId('tireSetRequest', props),
        onClick: handleTireSetRequest,
      },
      {
        type: 'button',
        variant: 'secondary',
        title: i18n.t('entity.tireSet.actions.transferToOrder'),
        isDisabled: hasArchivedWheel || formApi.formState.isDirty,
        'data-testid': suffixTestId('transferToOrder', props),
        onClick: transferToOrder,
      },
      {
        type: 'button',
        variant: 'secondary',
        title: i18n.t('entity.tireSet.actions.printLabel'),
        isDisabled: hasArchivedWheel || formApi.formState.isDirty,
        isLoading: isTiresSetLabelLoading,
        'data-testid': suffixTestId('printLabel', props),
        onClick: printLabel(selectedWheelIds),
      },
      {
        type: 'button',
        variant: 'secondary',
        title: i18n.t('entity.tireSet.actions.printDeliveryNote'),
        isDisabled: hasArchivedWheel || formApi.formState.isDirty,
        isLoading: isTiresSetDeliveryNoteLoading,
        'data-testid': suffixTestId('printDeliveryNote', props),
        onClick: printDeliveryNote(selectedWheelIds),
      },
    ];

    return [
      {
        title: i18n.t('entity.tireSet.labels.tires'),
        id: TIRES_TAB_ID,
        actions,
        'data-testid': suffixTestId('tires', props),
        content: (
          <VStack spacing={4}>
            <TireSetFormHeader
              control={control}
              formApi={formApi}
              setId={props.setId}
              orderId={props.tireOrderId}
              minNumberOfWheels={tireSet?.wheels?.length}
              isNumberOfWheelsDisabled={isNumberOfWheelsDisabled}
              isReadOnly={not(hasDifferentWheelState('ARCHIVED', formApi.getValues('wheels')))}
              data-testid={suffixTestId('header', props)}
            />
            <WheelArray
              control={control}
              formApi={formApi}
              existingWheels={tireSet?.wheels}
              readOnly={not(hasDifferentWheelState('ARCHIVED', formApi.getValues('wheels')))}
              data-testid={suffixTestId('wheelArray', props)}
            />
          </VStack>
        ),
      },
      {
        title: i18n.t('entity.tireSet.labels.tireMovement'),
        id: TIRES_MOVEMENT_TAB_ID,
        actions,
        'data-testid': suffixTestId('tireMovement', props),
        content: (
          <DataGrid
            autoHeight
            gridCode="tire-set-movement"
            queryModifier={queryModifier}
            data-testid={suffixTestId('tireMovement', props)}
          />
        ),
      },
    ];
  };

  return (
    <DataStatus isLoading={isLoading} isError={isTireSetError} minHeight={60}>
      <Form<TireSetFormType>
        schema={schema}
        onSubmit={handleSubmit}
        defaultValues={getEditTireSetDefaultValues(tenantPhoneInfo, tireSet)}
      >
        {(control, formApi) => (
          <VStack spacing={4}>
            <Tabs
              variant="condensed"
              tabs={getTabs(control, formApi)}
              activeTabId={activeTab}
              onChange={setActiveTab}
              data-testid={suffixTestId('tabs', props)}
            />
            <EditTireSetFooterButtons
              control={control}
              formApi={formApi}
              onClose={props.onClose}
              onEdit={props.onEdit}
              saveTires={saveTires}
              setId={props.setId}
              tireOrderId={props.tireOrderId}
              tireSet={tireSet}
              data-testid={suffixTestId('footer', props)}
            />
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const schema = object({numberOfTires: yupNumber.required()});
