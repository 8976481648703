import {FormControl, FormField} from 'platform/components';
import {Box, HStack, Show, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {always, concat, isNil, times} from 'ramda';

import {
  OccupiedSpaceRequestBody,
  useGetGeneralSettingsStorageLocationQuery,
  useGetTireWarehouseSettingsQuery,
} from '@dms/api';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {usePhoneNumbers} from '../../hooks/usePhoneNumbers';
import {useTireSetOptions} from '../../hooks/useTireSetOptions';
import {TireSetFormType} from '../../types/tires/TireSetFormType';
import {WheelType} from '../../types/tires/WheelType';
import {StorageLocation} from './components/StorageLocation';
import {StorageWarehouse} from './components/StorageWarehouse';
import {getStorageLocationPicker} from './utils/getStorageLocationPicker';

interface TireSetFormHeaderProps extends TestIdProps {
  control: FormControl<TireSetFormType>;
  formApi: UseFormReturn<TireSetFormType>;
  minNumberOfWheels?: number;
  isReadOnly?: boolean;
  isNumberOfWheelsDisabled?: boolean;
  setId?: string;
  orderId: string;
  onSelectStorageLocationWithoutId?: (selectedSpace: OccupiedSpaceRequestBody | undefined) => void;
  selectStorageLocationWithoutId?: OccupiedSpaceRequestBody;
}

const EMPTY_WHEEL: Partial<WheelType> = {};

export function TireSetFormHeader(props: TireSetFormHeaderProps) {
  const {mechanicOptions, locationOptions, defaultManufacturer} = useTireSetOptions();
  const {countriesOptions} = usePhoneNumbers();

  const {data: generalSettingsStorageLocation} = useGetGeneralSettingsStorageLocationQuery();
  const {data: tireWarehouseSettings} = useGetTireWarehouseSettingsQuery();

  const storageLocationPlace = getStorageLocationPicker(
    generalSettingsStorageLocation,
    tireWarehouseSettings
  );

  const handleNumberTiresChange = (value: number | null) => {
    if (isNil(value)) {
      return;
    }

    const wheels = props.formApi.getValues('wheels');

    let wheelsArray: WheelType[];

    if (value > wheels.length) {
      const dummyWheels = times(always(EMPTY_WHEEL), value - wheels.length);

      const fullWheels = dummyWheels.map((_, index) => ({
        manufacturer: defaultManufacturer?.id ?? '',
        location: locationOptions?.[(index + wheels.length) % locationOptions?.length]?.value,
      }));

      wheelsArray = concat(wheels, fullWheels);
    } else {
      wheelsArray = wheels.slice(0, value);
    }

    props.formApi.setValue('wheels', wheelsArray);
  };

  return (
    <VStack spacing={4}>
      <HStack spacing={4}>
        <Box flex={1}>
          <FormField
            control={props.control}
            name="contactPerson.name"
            type="text"
            label={i18n.t('entity.businessCase.labels.contactPerson')}
            data-testid={suffixTestId('contactPersonName', props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            control={props.control}
            name="contactPerson.phoneNumber"
            type="phone"
            countries={countriesOptions}
            label={i18n.t('general.labels.phone')}
            data-testid={suffixTestId('contactPersonPhone', props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            control={props.control}
            name="contactPerson.email"
            type="email"
            label={i18n.t('general.labels.email')}
            data-testid={suffixTestId('contactPersonEmail', props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            control={props.control}
            name="note"
            type="text"
            label={i18n.t('general.labels.note')}
            data-testid={suffixTestId('note', props)}
          />
        </Box>
      </HStack>
      <HStack spacing={4}>
        <Box flex={1}>
          <FormField
            control={props.control}
            name="assignMechanic"
            type="choice"
            label={i18n.t('entity.tireSet.labels.mechanic')}
            options={mechanicOptions}
            menuInPortal
            isDisabled={props.isReadOnly}
            data-testid={suffixTestId('mechanic', props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            control={props.control}
            name="storedUntil"
            type="apiDate"
            label={i18n.t('entity.tireSet.labels.storedUntil')}
            isRelativeDatesHidden
            isDisabled={props.isReadOnly}
            data-testid={suffixTestId('storedUntil', props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            control={props.control}
            name="numberOfTires"
            type="integer"
            label={i18n.t('entity.tireSet.labels.numberOfTires')}
            data-testid={suffixTestId('numberOfTires', props)}
            isRequired
            isStepperVisible
            minStepperValue={props.minNumberOfWheels}
            isDisabled={props.isReadOnly || props.isNumberOfWheelsDisabled}
            onChange={handleNumberTiresChange}
          />
        </Box>
        <Box flex={1}>
          <Show when={storageLocationPlace === 'STORAGE_LOCATION'}>
            <StorageWarehouse
              setId={props.setId}
              orderId={props.orderId}
              isDisabled={props.isReadOnly || false}
              data-testid={suffixTestId('storageWarehouse', props)}
            />
          </Show>
          <Show when={storageLocationPlace === 'STORAGE_SECTOR'}>
            <StorageLocation
              setId={props.setId}
              isDisabled={props.isReadOnly}
              selectStorageLocationWithoutId={props.selectStorageLocationWithoutId}
              onSelectStorageLocationWithoutId={props.onSelectStorageLocationWithoutId}
              data-testid={suffixTestId('storageLocation', props)}
            />
          </Show>
          <Show when={storageLocationPlace === 'MANUAL'}>
            <FormField
              control={props.control}
              name="storedLocation"
              type="text"
              label={i18n.t('entity.tireSet.labels.storedLocation')}
              isDisabled={props.isReadOnly}
              data-testid={suffixTestId('storedLocation', props)}
            />
          </Show>
        </Box>
      </HStack>
    </VStack>
  );
}
