import {
  Box,
  Center,
  Clickable,
  Heading,
  HStack,
  Icon,
  Spinner,
  Text,
  ThemeColorTextPath,
  VStack,
} from 'platform/foundation';
import {css} from 'styled-components';

import {ReactNode, Suspense, useEffect, useState} from 'react';

type LazyImageImport = Promise<{default: string}>;

const BANNERS = {
  bgRemoval: () => import('./assets/backgroundRemoval.png') as LazyImageImport,
  taskManagement: () => import('./assets/taskManagement.png') as LazyImageImport,
  digitalSignature: () => import('./assets/digitalSignature.png') as LazyImageImport,
} as const;

export interface AdBannerProps {
  headingColor?: ThemeColorTextPath;
  subheadingColor?: ThemeColorTextPath;
  bgImage: keyof typeof BANNERS;
  heading: string;
  subheading?: string;
  onClick: () => void;
}

//TODO: Temporary fix for react 18
const Background = ({children, bgImage}: {children: ReactNode; bgImage: LazyImageImport}) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    bgImage.then((img) => setImageUrl(img.default));
  }, [bgImage]);

  if (!imageUrl) {
    return (
      <Center minHeight={55} minWidth={80}>
        <Spinner />
      </Center>
    );
  }

  return (
    <div
      css={css`
        background-image: url(${imageUrl});
        background-size: cover;
        border-radius: ${({theme}) => theme.getSize(2)};
        min-width: ${({theme}) => theme.getSize(80)};
        min-height: ${({theme}) => theme.getSize(55)};
        padding: ${({theme}) => theme.getSize(5)};
      `}
    >
      {children}
    </div>
  );
};

export function AdBanner(props: AdBannerProps) {
  const {headingColor = 'primary', subheadingColor = 'secondary'} = props;

  return (
    <Suspense
      fallback={
        <Center minHeight={55} minWidth={80}>
          <Spinner />
        </Center>
      }
    >
      <Clickable onClick={props.onClick}>
        <Background bgImage={BANNERS[props.bgImage]?.()}>
          <HStack>
            <VStack spacing={2} maxWidth={58}>
              <Heading size={4} color={headingColor}>
                {props.heading}
              </Heading>
              <Text size="xSmall" color={subheadingColor}>
                {props.subheading}
              </Text>
            </VStack>
            <Box width={10} height={10} borderRadius="circular" backgroundColor="general.white">
              <Center height="100%">
                <Icon value="navigation/arrow_forward_ios" size={4} />
              </Center>
            </Box>
          </HStack>
        </Background>
      </Clickable>
    </Suspense>
  );
}
