/* eslint-disable eag/match-named-export */
import i18next from 'i18next';
import {input, output, z, ZodEffects, ZodTypeAny} from 'zod';
import {errorUtil} from 'zod/lib/helpers/errorUtil';

import {isNil, isNotNil} from 'ramda';
import {isNilOrEmpty, isNotDate, isNotNumber, isNotString} from 'ramda-adjunct';

import {Nullish} from '../types/Nullish';

/**
 * Helper function that allow to chain .required() zod schema on custom utility schemas.
 * Using this function will mark given schema as required if the schema will not pass the refine method.
 *
 * @returns Inferred type from the output<T> with excluded nullish type.
 *
 * Example:
 * ```typescript
 *  const schema = zodString; // string | null | undefined
 *  const requiredSchema = zodString.required() // string
 * ```
 */
function extendWithRequired<T extends ZodTypeAny>(
  schema: T
): T & {required: () => ZodEffects<T, Exclude<output<T>, Nullish>, input<T>>} {
  return Object.assign(schema, {
    required: () =>
      schema.refine((value) => isNotNil(value), {
        message: i18next.t('errors.field_is_required'),
      }) as ZodEffects<T, Exclude<output<T>, Nullish>, input<T>>,
  });
}

/**
 * A core Zod string schema that:
 * - Ensures the input is trimmed string value.
 * - Returns `null` if not valid.
 *
 * Example:
 * ```typescript
 * const schema = zodStringCore;
 * schema.parse("   "); // Returns `null`
 * schema.parse(123); // Returns `null`
 * schema.parse("  valid string  "); // "valid string"
 * ```
 */
const zodStringCore = z
  .string()
  .transform((value) => {
    const trimmedValue = value.trim();
    if (isNotString(value) || isNilOrEmpty(trimmedValue)) {
      return null;
    }
    return trimmedValue;
  })
  .nullable()
  .optional();

/**
 * A custom Zod string schema with chainable utility methods.
 *
 * Features:
 * - **Validation**:
 *   - Trims whitespace and ensures the input is a non-empty string.
 *   - Returns `null` if not valid.
 * - **Required**:
 *   - Use `.required()` to force truthy string values.
 * - **Constraints**:
 *   - `.min(value)`: Enforces a minimum string length.
 *   - `.max(value)`: Enforces a maximum string length.
 *   - `.minMax(min, max)`: Combines `.min()` and `.max()` constraints.
 *
 * Example:
 * ```typescript
 * const schema = zodString.minMax(5, 10);
 * schema.parse("   example   "); // "example"
 * schema.parse(undefined); // Validation error, returns `null`
 * schema.parse(""); // Validation error, returns `null`
 *
 * const schema = zodString.required();
 * schema.parse("") // Validation error
 * schema.parse(null) // Validation error
 * schema.parse(" example") // "example"
 * ```
 */
export const zodString = extendWithRequired(
  Object.assign(zodStringCore, {
    min: (value: number) =>
      extendWithRequired(zodStringCore.pipe(z.string().min(value).nullable())),
    max: (value: number) =>
      extendWithRequired(zodStringCore.pipe(z.string().max(value).nullable())),
    minMax: (min: number, max: number) =>
      extendWithRequired(zodStringCore.pipe(z.string().min(min).max(max).nullable())),
  })
);

/**
 * A core Zod number schema that:
 * - Ensures the input is a valid number.
 * - Returns `null` if not valid.
 *
 * Example:
 * ```typescript
 * const schema = zodNumberCore;
 * schema.parse(42); // 42
 * schema.parse(null); // Returns `null`
 * schema.parse(undefined); // Returns `null`
 * schema.parse("not a number"); // Returns `null`
 * ```
 */
const zodNumberCore = z
  .number()
  .transform((value) => {
    if (isNotNumber(value) || isNil(value)) {
      return null;
    }
    return value;
  })
  .nullable()
  .optional();

/**
 * A custom Zod number schema with chainable utility methods.
 *
 * Features:
 * - **Validation**:
 *   - Ensures the input is a valid number value.
 *   - Returns `null` if not valid.
 * - **Required**:
 *   - Use `.required()` to force truthy number values.
 * - **Constraints**:
 *   - `.positive()`: Ensures the number is strictly greater than 0.
 *   - `.min(value)`: Enforces a minimum number constraint.
 *   - `.max(value)`: Enforces a maximum number constraint.
 *   - `.minMax(min, max)`: Combines `.min()` and `.max()` constraints.
 *
 * Example:
 * ```typescript
 * const schema = zodNumber.required();
 * schema.parse(42) // 42
 * schema.parse(null) // Validation error
 * schema.parse("not a number") // Validation error
 * ```
 */
export const zodNumber = extendWithRequired(
  Object.assign(zodNumberCore, {
    positive: () => extendWithRequired(zodNumberCore.pipe(z.number().positive().nullable())),
    min: (value: number) =>
      extendWithRequired(zodNumberCore.pipe(z.number().min(value).nullable())),
    max: (value: number) =>
      extendWithRequired(zodNumberCore.pipe(z.number().max(value).nullable())),
    minMax: (min: number, max: number) =>
      extendWithRequired(z.number().min(min).max(max).nullable()),
  })
);

/**
 * A core Zod date schema that:
 * - Ensures the input is a valid date.
 * - Returns `null` if not valid.
 *
 * Example:
 * ```typescript
 * const schema = zodDateCore;
 * schema.parse(new Date("2023-01-01")); // Valid Date
 * schema.parse("not a date"); // Returns `null`
 * schema.parse(undefined); // Returns `null`
 * schema.parse(null); // Returns `null`
 * ```
 */
const zodDateCore = z
  .date()
  .transform((value) => {
    if (isNotDate(value) || isNilOrEmpty(value)) {
      return null;
    }
    return value;
  })
  .nullable()
  .optional();

/**
 * A custom Zod date schema with chainable utility methods.
 *
 * Features:
 * - **Validation**:
 *   - Ensures the input is a valid date value.
 *   - Returns `null` if not valid.
 * - **Required**:
 *   - Use `.required()` to force truthy date values.
 * - **Constraints**:
 *   - `.min(value, message?)`: Enforces a minimum date constraint.
 *   - `.max(value, message?)`: Enforces a maximum date constraint.
 *
 * Example:
 * ```typescript
 * const schema = zodDate.min(new Date("2022-01-01"));
 * schema.parse(new Date("2023-01-01")); // Valid Date
 * schema.parse(undefined); // Returns `null`
 * schema.parse(null); // Returns `null`
 * schema.parse(new Date("2021-01-01")); // Validation error
 * schema.parse("not a date"); // Returns `null`
 * ```
 */
export const zodDate = extendWithRequired(
  Object.assign(zodDateCore, {
    min: (value: Date, message?: errorUtil.ErrMessage) =>
      extendWithRequired(zodDateCore.pipe(z.date().min(value, message).nullable())),
    max: (value: Date, message?: errorUtil.ErrMessage) =>
      extendWithRequired(zodDateCore.pipe(z.date().max(value, message).nullable())),
  })
);
